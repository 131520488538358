
.instances-table1>:not(:last-child)>:last-child>* {
    border-bottom-color: inherit;
}
.instances-table-header{
    display: flex;
}
.instances-table-header p{
    margin-bottom: 0px;
    width: 100%;
    margin-right: 15px;
    text-align: left;
}
.instances-table-header{
    text-align: end;
}
.add-row-button .btn-primary, .addnew-row-wrapper .btn-primary{
    background-color: transparent !important;
    color: #000 !important;
    border-color: transparent !important;
}
.instances-table1 tbody, .instances-table1 td, .instances-table1 tfoot, .instances-table1 th, .instances-table1 thead, .instances-table1 tr{
    border-width: 1px !important;
}
.action-btn{
    width: 5% !important;
}
.action-btn p{
    margin-right: 0px;
}
.opecity{
    opacity: 0.2;
}
.blank_div{
    position: absolute;
    height: 100%;
    z-index: 1000;
    width: 94%;
    background-color: #ccc;
    opacity: 0.1;
}
.instances-table-del-cell p{
    margin-bottom: 0px;
}