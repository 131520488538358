.observations_tittle{
    align-items: center;
    justify-content: space-between;
}
.ck-content p{
    font-size: 12px;
    font-weight: normal;
}
.editor1 .ck-editor__top,
.editor2 .ck-editor__top,
.editor3 .ck-editor__top,
.editor4 .ck-editor__top{
    display: none;
} 

.editable-cell {
    position: relative;
  }
  
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }
  
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  
  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
  .observations_tittle .form-check{
    float: right;
  }
  .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners { 
    width: 100%;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.3em;
}
.scrollable-modal .modal-body {
  max-height: calc(100vh - 3.5rem);
  overflow-y: auto;
}
.scrollable-modal .modal-content {
  max-height: calc(100vh - 3.5rem);
  overflow: hidden;
}
.ql-editor[contenteditable=false] {
  background-color: rgb(249, 249, 249);
}
.ql-snow .ql-tooltip{
    top: 37px !important;
    left: 0 !important;
    z-index: 1;
}
.two-lines .ql-toolbar.ql-snow {
  display: none;
}

.two-lines .ql-container.ql-snow {
  border-top: 1px solid #ccc !important;
  border-radius: 4px;
}

.two-lines-focus .ql-toolbar.ql-snow {
  display: block;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.two-lines-focus .ql-container.ql-snow {
  border-top: 0px solid #ccc !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.two-lines .ql-container.ql-snow {
  height: 62px;
}
.feedback .ql-toolbar.ql-snow{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.feedback .ql-container.ql-snow {
  height: 150px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.feedback-focus .ql-container.ql-snow {
  height: 150px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.two-lines-focus .ql-container.ql-snow {
  height: 62px;
}