.registerForm h1{
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 3px;
    display: flex;
    align-items: center;
    color: #22577E;
    font-weight: 300;
}
.registerForm label{
    font-size: 14px;
    font-weight: 300;
    color: #5584AC;
}
.registerForm .form-control, .registerForm .form-select{
    height: 40px;
    border-color: #5584AC;
    color: #5584AC;
    font-size: 14px;
    font-weight: 300;
}
.registerForm .form-control::placeholder{
    color: #5584AC;
}
.registerForm .btn-primary{
    background-color: #552B84;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    width: 129px;
    height: 40px;
    border-color: transparent;
}
.register_details{
    height: 100vh;
    background: #552B84;
    display: flex;
    align-items: center;
    justify-content: center;
}
.register_info{
    background: #FFFFFF;
    box-shadow: 0px 12px 12px rgba(61, 178, 255, 0.1);
    border-radius: 6px;
    max-width: 339px;
}
.register_info p{
    color: #22577E;
    font-weight: 300;
    font-size: 16px;
}
.register_box{
    display: flex;
    align-items: center;
    min-height: 100%;
}
.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.3em;
}