.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    height: 32px; /* Set the desired height */
    border-color: rgba(85, 43, 132, 0.3) !important; /* Set the desired border color */
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.table-top-border .ant-table-tbody > tr:first-child > td{
    border-top: 1px solid #e0d9e9 !important;
}
.ant-table-thead > tr > th {
    background-color: #e0d9e9 !important;
}
.anticon-left, .anticon-right {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.ant-table-cell-row-hover{
    background-color: #ffffff !important;
}
.anticon-arrow-up, .anticon-arrow-down{
    margin-left: 5px !important;
}
.anticon-plus, .anticon-edit, .anticon-delete {
    margin-right: 10px !important;
}
.anticon-more{
    font-size: medium;
}
.ant-pagination-item-active{
    border: 2px solid rgba(85, 43, 132, 0.3) !important; /* Set the desired border color */
}
.ant-pagination-item:hover{
    border-color:#552b84 !important;
}
.ant-pagination-item:hover a{
    color: #552b84 !important;
}
.ant-pagination-prev:hover .ant-pagination-item-link{
    color: #552b84 !important;
    border-color: #552b84 !important;
}
.ant-pagination-next:hover .ant-pagination-item-link {
    color: #552b84 !important;
    border-color: #552b84 !important;
}
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #552b84 !important;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #552b84 !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color:#552b8433 !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-color: #552b84 !important;
}
.ant-select .ant-select-selector{
    border-color: #552b84 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.2) !important;
}
.custom-borderless-table .ant-table-tbody > tr > td{
    border-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    height: 26px;
}
.table-container {
    display: flex;
    justify-content: center;
}
.custom-borderless-table .ant-table-wrapper {
    text-align: left;
}
.ant-table-tbody > tr.ant-table-row:hover > td, 
.ant-table-tbody > tr > td.ant-table-cell-row-hover{
    background-color: #FFFFFF !important;
}
.custom-modal{
    width: 465px !important;
}
.custom-modal .ant-modal-header {
    text-align: center;
}
.custom-modal .ant-modal-footer {
    text-align: center;
}
.custom-common-modal{
    text-align: center;
    width: 410px !important;
    border-top: 0px !important;
}
.custom-common-modal .ant-modal-footer {
    text-align: center;
    border-top: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 15px !important;
}
.separator{
    margin-right: 10px;
    margin-left: 10px;
}
.heading-item{
    width: 112px;
}
.heading-item-bold{
    width: 112px;
    font-weight: bold;
}
.value-bold{
    font-weight: bold;
}
.editable-cell {
    position: relative;
    padding: 0px 4px;
}
.editable-cell-value {
    cursor: pointer;
    min-height: 31.5px;
    padding-left: 4px !important;
}
.textarea{
    width: 172px !important;
}
.input{
    width: 150px !important;
}
.editable-row:hover .editable-cell-value {
    min-height: 31.5px;
    font-size: 11.3px;
    border: 1px solid #552B84;
    border-radius: 0.25rem;
    justify-content: space-between;
    display: flex;
    /* width: 150px !important; */
    align-items: center;
}
.textarea:hover{
    width: 178px !important;
}
.input:hover{
    width: 150px !important;
}
.custom-table-height .ant-table-tbody > tr > td{
    height: 31.5px;
}
.ant-empty{
    color: black;
}