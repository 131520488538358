.react-bs-table-sizePerPage-dropdown .btn-secondary{
    width: 50px;
    display: inline-block;
    border-radius: 50px;
    box-shadow: none !important;
    border: 1px solid #552B84;
    outline: none;
    padding: 5px;
    background-color: transparent;
    height: 30px;
    color: #000;
    font-size: 12px;
    /* position: absolute; */
    left: 0px;
}
.search-width{
    width: 170px;
}
.search-label .form-control {
    border-radius: 50px;
    box-shadow: none;
    border: 1px solid #552B84;
    outline: none;
    padding: 6px;
}
.react-bootstrap-table-page-btns-ul .page-item .page-link{
    position: relative;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #555 !important;
    background-color: #fff;
    border: 1px solid #dee2e6;
    text-decoration: none;
    cursor: pointer;
    font-size: 12px;
    box-shadow: none !important;
}
.react-bootstrap-table-page-btns-ul .page-item .page-link:hover{
    background-color: rgba(85, 43, 132, 0.3);
    color: #fff !important;
}
.react-bootstrap-table-page-btns-ul .active .page-link{
    background-color: rgba(85, 43, 132, 0.3);
    color: #fff !important;
}
.react-bootstrap-table-page-btns-ul {
    float: left;
    margin-top: 0!important;
}
.sortable{
    font-weight: 700;
}
.table>:not(:first-child) {
    border-top: 1px solid #000;
}
.react-bootstrap-table-pagination-total{
    float: right;
    font-size: 12px;
}
.search-label{
    margin-left: 60px;
    position: absolute;
    top: 9px;
}
.react-bootstrap-table{
    margin-top: 15px;
}

.sorting.sorting_asc{
    width: 50px !important;
}
.action{
    width: 30px !important; 
}
.action, .edit_icons{
    text-align: right;
}
.sr_no{
    width: 10px !important;
}
.sr_bookstock_no{
    width: 18px !important;
}
.status{ 
    width: 20px !important;
    text-align: center;
}