
.add_btns{
    z-index: 1000;
}
.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.3em;
}
.account-table .search-label{
    visibility: hidden;
}