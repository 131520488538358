.login_details{
    width: 40%;
    box-shadow: 0 1px 3px rgb(0 0 0 / 15%);
    background-color: #fff;
    padding: 25px;
}
.login_box{
    min-height: 100vh;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.login_box h1 {
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 3px;
    display: flex;
    align-items: center;
    color: #22577E;
    font-weight: 300;
    justify-content: center;
}
.login_box label {
    font-size: 12px;
    font-weight: 300;
    color: #000;
}
.forgot_btn a{
    font-size: 12px;
    font-weight: 300;
    color: #5584AC;
}
.login_box  .form-control{
    height: 40px;
    border-color: #5584AC;
    color: #000;
    font-size: 14px;
    font-weight: 300;
    background-color: #F7F7F7;
}
.login_details .form-check{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.login_details .btn-primary{
    background-color: #552B84;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    width: 129px;
    height: 40px;
    border-color: transparent;
}
.forgot_btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
}