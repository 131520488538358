.modal-delet-icon {
    min-height: 100%;
    justify-content: space-between;
}

.reconlable p {
    margin-bottom: 5px;
    font-size: 12px;
    color: #212529;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.3em;
}

.css-1insrsq-control {
    background-color: #e9ecef !important;
    opacity: 1 !important;
    color: #212529 !important;
    border: 1px solid #552B84 !important;
    min-height: 33px !important;
    height: 33px !important;
}

.css-lr9fot-singleValue {
    color: #212529 !important;
    font-weight: 400;
}

.reconcilation-select .css-1s2u09g-control {
    min-height: 31.5px !important;
    height: 31.5px !important;
}

.reconcilation-select .css-tj5bde-Svg {
    margin-top: -3px;
}
.css-1pahdxg-control:hover {
    border-color: #552B84 !important;
}
.reconcilation-select .css-1pahdxg-control {
    min-height: 31.5px !important;
    height: 31.5px !important;
    box-shadow: 0 0 0 0 #552B84 !important ;
    border-color: #552B84 !important;
}
.reconcilation-select .css-319lph-ValueContainer {
    margin-top: -2.5px;
}
.reconcilation-select .css-1okebmr-indicatorSeparator {
    margin-top: 5px;
}
.reconcilation-select .css-1s2u09g-control:focus {
    min-height: 31.5px !important;
    height: 31.5px !important;
}

/* .reconcilation-select .css-1pahdxg-control {
    min-height: 31.5px !important;
    height: 31.5px !important;
} */

/* .reconcilation-select .css-tj5bde-Svg {
    margin-top: -3px;
} */
.error{
    font-size: 10px;
    color: red;
    margin-bottom: 0px;
}