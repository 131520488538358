.has-search .form-control {
    padding-left: 2.375rem;
}
.has-search{
    position: relative;
}
.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #552B84;
    top: -5px;
}
th, td {
    width: 65px !important;
}
.deviation-box th, td{
    width: auto !important;
}
.table-responsive{
    overflow: hidden;
}

.sample{
    cursor: pointer;
}
.sampling_box .form-control{
    width: 48px;
    border: none;
    padding: 0px;
    border-bottom: 1px solid #ced4da;
    text-align: center;
}
.sampling_box  .form-select{
    width: 90px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}
.sampling_box .form-control, .sampling_box  .form-select{
    margin: 0px 5px;
    box-shadow: none;
    outline: none;
    border-radius: 0px;
}
.sampling_details{
    font-size: 11.5px;
    align-items: center;
    justify-content: flex-start;
}
.form-control:focus, .form-select:focus{
    border-color: #552B84;
}
#sampling .modal-lg{
    max-width: 750px;
}
.sampling_details .col-md-2, .sampling_details .col-md-1{
    min-height: 100%;
    display: flex;
    align-items: center;
}
.rule_width{
    width: auto;
}
.add_btn_margin {
    margin-left: 10px;
}
.sampling-add_btn{
    text-align: right;
}
.ongoing-clients h6{
    font-size: 12px;
    margin-bottom: 0px;
}
.ongoing_box h6{
    margin-bottom: 0px;
}
.ongoing_box .table{
    margin-bottom: 0px;
}
.observation_tittle{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.add_table{
    text-align: left;
}

.ongoing_box .table>:not(:first-child) {
    border-top: 1px solid currentColor;
}
.css-14el2xx-placeholder, .css-g1d714-ValueContainer{
    font-size: 11.5px;
    color: #212529;
}
.css-1s2u09g-control{
    min-height: 31.25px;
}
#newscheduled .form-control{
    height: 38px;
}
.modal-90w{
    max-width: 750px !important;
}
.red-label{
    color: red;
}
.green-label{
    color: green;
}
.obser-tittle{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.span_list span{
    margin-right: 10px;
    font-size: 11.5px;
}
.static_search-bar .form-control{
    border-radius: 50px;
    box-shadow: none;
    border: 1px solid #552B84;
    outline: none;
    padding: 5px;
    padding-left: 30px;
}

/* Seach Stock CSS */
.actualstock input {
    font-family: 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 13px;
    color: #555860;
}

.actualstock .search {
    position: relative;
    width: 300px;
}

.actualstock .search input {
    height: 26px;
    width: 100%;
    padding: 0 12px 0 25px;
    background: white url("https://cssdeck.com/uploads/media/items/5/5JuDgOa.png") 8px 6px no-repeat;
    border-width: 1px;
    border-style: solid;
    border-color: #a8acbc #babdcc #c0c3d2;
    border-radius: 13px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
    -moz-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
    -ms-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
    -o-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
    box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;

}

.actualstock .search input:focus {
    outline: none;
    border-color: #66b1ee;
    -webkit-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
    -moz-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
    -ms-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
    -o-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
    box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
}

.actualstock .search input:focus + .results { display: block }

.actualstock .search .results {
    display: none;
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    border-width: 1px;
    border-style: solid;
    border-color: #cbcfe2 #c8cee7 #c4c7d7;
    border-radius: 3px;
    background-color: #fdfdfd;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fdfdfd), color-stop(100%, #eceef4));
    background-image: -webkit-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -moz-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -ms-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -o-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: linear-gradient(top, #fdfdfd, #eceef4);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    height: 250px;
    overflow: auto;
}

.actualstock .search .results li { display: block }

.actualstock .search .results li:first-child { margin-top: -1px }

.actualstock .search .results li:first-child:before, .actualstock .search .results li:first-child:after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    margin-left: -5px;
    border: 5px outset transparent;
}

.actualstock .search .results li:first-child:before {
    border-bottom: 5px solid #c4c7d7;
    top: -11px;
}

.actualstock .search .results li:first-child:after {
    border-bottom: 5px solid #fdfdfd;
    top: -10px;
}

.actualstock .search .results li:first-child:hover:before, .actualstock .search .results li:first-child:hover:after { display: none }

.actualstock .search .results li:last-child { margin-bottom: -1px }

.actualstock .search .results a {
    display: block;
    position: relative;
    margin: 0 -1px;
    padding: 6px 40px 6px 10px;
    color: #808394;
    font-weight: 500;
    text-shadow: 0 1px #fff;
    border: 1px solid transparent;
    border-radius: 3px;
}

.actualstock .search .results a span { font-weight: 200 }

.actualstock .search .results a:before {
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -9px;
    background: url("https://cssdeck.com/uploads/media/items/7/7BNkBjd.png") 0 0 no-repeat;
}

.actualstock .search .results a:hover {
    text-decoration: none;
    color: #fff;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.3);
    border-color: #2380dd #2179d5 #1a60aa;
    background-color: #338cdf;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #59aaf4), color-stop(100%, #338cdf));
    background-image: -webkit-linear-gradient(top, #59aaf4, #338cdf);
    background-image: -moz-linear-gradient(top, #59aaf4, #338cdf);
    background-image: -ms-linear-gradient(top, #59aaf4, #338cdf);
    background-image: -o-linear-gradient(top, #59aaf4, #338cdf);
    background-image: linear-gradient(top, #59aaf4, #338cdf);
    -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
    -ms-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
    -o-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
}

:-moz-placeholder {
    color: #a7aabc;
    font-weight: 200;
}

::-webkit-input-placeholder {
    color: #a7aabc;
    font-weight: 200;
}

.lt-ie9 .search input { line-height: 26px }
.book-box-width{
width: 160px;
}
.book-box{
    background: rgba(85, 43, 132, 0.18);
}
.actualbox{
    color: #552B84;
}
.book-box a i{
    color: #552B84;
}
.actualbox{
    width: 100%;
    margin: auto;
}
.actual-form .form-control{
    border-color: #552B84;
    border-radius: 0px;
}

/* pv */

.searchContainer{
   width: 40% !important;
   margin-bottom: 5px;
   /* width: 40% !important; */
}
.searchbar{
    border-radius: 20px;
    border: 1px solid purple;
    width: 210px;
    font-size: 14px;
    padding-left: 10px;
    height: 30px;
    outline: none;
}

.suggestions{
    width: 320px;
    overflow-x: hidden;
    overflow-y: auto; 
    padding: 10px;
    font-size: 14px;
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid purple;
    max-height: 250px;
    background-color: white !important;
    position: absolute;
    z-index: 1 !important;
}
 
.suggestions button{
    text-align: left;
    background-color: white !important;
    border: none;
}

.suggestions::-webkit-scrollbar {
    width: 5px; 
    max-height: 30px !important;
  }
   
.suggestions::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(230, 77, 77, 0); 
    height: 30px !important;
}

.suggestions::-webkit-scrollbar-thumb {
    background-color: rgba(128, 0, 128, 0.349); 
    outline: none;
    max-height: 30px !important;
}
.singleSearchSuggestion{
    width: 100%;
    font-size: 13px;
}

.modal-labels{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.assignments h6{
    font-size: 14px;
}
.ongoing_box .table{
    background: rgba(85, 43, 132, 0.18) !important;
}
.observation-table .search-label{
    /* top: 0px; */
    display: none !important;
}
.observation-table .add_btn{
    position: absolute;
    right: 0px;
}
.observation-table .order_observation_btn {
    position: absolute;
    right: 8%;
}
.observation-table .search_icon{
    top: 7px;
    display: none !important;
}
.detailslable{
    font-size: 11.5px;
}
.bookstockheader{
    border-color: rgba(85, 43, 132, 0.18) !important;
} 

.ongoingbutton .btn-primary {
    background-color: #552B84 !important;
    color: #fff !important;
    border-color: #552B84 !important;
    padding: 5px 15px !important;
    font-size: 12px !important;
    margin-right: 8px !important;
}

.sampling-table .table {
    background:white !important;
}
.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.3em;
}
.coustom-font-style {
    font-weight: 600 !important;
    font-size: 12.5px !important;
}
.ww-25 {
    border: 1px solid #552B84 ;
    width: 7%!important;
    margin-right: 15px;
}
/* .form-coustom-select {
 width: 180px;
 font-size: 13px;
 border: 1px solid #552B84 !important;
 outline: none;
 box-shadow: none !important;
 border-radius: 4px;
 min-height: 20px !important;
} */
.material-header {
    border: 1px solid #000000;
    padding: 6px;
    border-radius: 5px;
    font-size: 12.5px;
    flex: 1;
    display: flex;
}
.custom-observation-header-class{
    width: 350px !important;

}
.custom-reviewer-header-class{
    padding-left:30px !important;

}
.center-horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.my-modal {
    width: 30vw !important;
    max-width: 30vw !important;
}
.day-box {
    width: 90px;
    height: 31.5px;
}
.select-dropdown {
    width: 200px;
}
.header-container {
    width: 82.5%;
    align-items: center;
    bottom: 0;
    position: absolute;
    display: flex;
}

.icon-container {
    margin-left: auto; /* Push the icon to the right side */
    display: flex; /* Use flexbox */
    align-items: center; /* Vertically center the icon */
}

@media only screen and (min-width:460px) and (max-width: 786px) {
   .observation-table{
        overflow: auto;
   }
   .my-modal{
        width: 50vw !important;
        max-width: 50vw !important;
   }
   .searchContainer{
        /* width: 23% !important; */
        width: 50% !important;
    }
    .day-box {
        width: 40px;
    }
}

@media only screen and (min-width:786px) and (max-width:1250px) {
    .observation-table{
        overflow: auto;
    }
    .my-modal{
        width: 40vw !important;
        max-width: 40vw !important;
    }
    .searchContainer{
        /* width: 23% !important; */
        width: 50% !important;
     }
    .day-box {
        width: 42px;
    }
    .select-dropdown {
        width: 180px;
    }
}
@media only screen and (min-width:1250px) and (max-width:1273px) {
    .day-box {
        width: 44px;
    }
    .rule_width, .pr_rule_width {
        padding-left: 0 !important;
        padding-right: 1px !important;
    }
}
@media only screen and (min-width:1273px) and (max-width:1394px) {
    .day-box {
        width: 50px;
    }
    .rule_width, .pr_rule_width {
        padding-left: 0 !important;
        padding-right: 4px !important;
    }
}
@media only screen and (min-width:1394px) and (max-width:1430px) {
    .day-box {
        width: 70px;
    }
    .rule_width {
        padding-left: 0 !important;
        padding-right: 8px !important;
    }
}
@media only screen and (min-width:1430px) and (max-width:1454px) {
    .day-box {
        width: 76px;
    }
    .rule_width {
        padding-left: 0 !important;
        padding-right: 10px !important;
    }
}
@media only screen and (min-width:1454px) and (max-width:1514px) {
    .day-box {
        width: 80px;
    }
    .rule_width {
        padding-left: 0 !important;
        padding-right: 12px !important;
    }
}
@media only screen and (min-width:1514px) and (max-width:1580px) {
    .searchContainer {
        width: 30% !important;
    }
    .rule_width {
        padding-left: 0 !important;
        padding-right: 15px !important;
    }
}
@media only screen and (min-width:1580px) and (max-width:1648px) {
    .searchContainer {
        width: 30% !important;
    }
    .rule_width {
        padding-left: 5px !important;
    }
}
@media only screen and (min-width:1648px) and (max-width:1735px) {
    .searchContainer {
        width: 30% !important;
    }
}
@media only screen and (min-width:1735px) and (max-width:1920px) {
    .searchContainer {
        width: 30% !important;
    }
    .rule_width {
        margin-left:0 !important;
        margin-right: 0 !important;
    }
}