.ant-tabs-tab{
    padding: 2px 0 !important;
    vertical-align: middle;
}
.ant-tabs-tab:hover{
    color: #552b84 !important;
}
.ant-tabs-tab-btn{
    color: #552b84 !important;
}
.ant-tabs-ink-bar{
    background: #552b84 !important;
    width: 40px !important;
    position: absolute;
    margin: 0 auto !important;
}
.ant-tabs-tab + .ant-tabs-tab{
    margin: 0 0 0 12px !important;
}
.ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: 0px !important;
}