body{
    font-weight: 500;
    font-family: 'Inter', sans-serif !important;
}
.navbar {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
}
.main_user_nav{
    display: flex;
    align-items: center;
}
.user_box{
    display: flex;
    align-items: center;
}
.main_tabs #v-pills-tab .nav-link{
    padding: 25px;
    border-bottom: 1px solid rgba(85, 43, 132, 0.3);
    border-radius: 0px;
    color: #000 !important;
    display: grid;
    text-align: center;
    border-top: none !important;
    
}
.nav-pills .nav-link i{
    font-size: 24px;
    margin-right: 0px !important;
    color: #552B84;
    margin-bottom: 8px;
}
.master_boxinfo .nav-tabs .nav-link{
    border-radius: .25rem;
}
.master_boxinfo .nav-tabs .nav-link:hover{
    background-color: rgba(85, 43, 132, 0.18);
    color: #fff;
    border-radius: .25rem;
}
.main_tabs #v-pills-tab .nav-link.active, .main_tabs #v-pills-tab .show>.nav-link {
    background-color: #fff;
    border-left: 10px solid #552B84 !important;
}
.main_tabs #v-pills-tab{
    background: rgba(85, 43, 132, 0.18);
    height: 100% !important;
    min-height: 100% !important;
    position: fixed;
    width: 15%;
    top: 60px;
}
.master_boxinfo h2{
    font-weight: 300;
    color: #4A4A4A;
}
.master_boxinfo #pills-tab .nav-link{
    border: 1px solid transparent;
    color: #552B84;
    margin-left: 15px;
    width: auto;
    height: auto;
    font-size: 12px;
}
.master_boxinfo #pills-tab .nav-link.active, .master_boxinfo #pills-tab .show>.nav-link{
    background-color: #552B84;
    color: #fff;
}
.edit_icons a{
    margin: 5px;
    font-size: 13px;
}
table {
    caption-side: bottom;
    border-collapse: collapse;
    font-size: 11.5px;
}
.main_user_nav h5{
    font-size: 13px;
}
.paginate_button{
    position: relative;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #555 !important;
    background-color: #fff;
    border: 1px solid #dee2e6;
    text-decoration: none;
    cursor: pointer;
    font-size: 12px;
}
.paginate_button:hover{
    background-color:  rgba(85, 43, 132, 0.3);
    color: #fff !important;
}
.paginate_button.current{
    background-color:  rgba(85, 43, 132, 0.3);
    color: #fff !important;
}
.paginate_button:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.paginate_button:last-child{
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.edit_icons .dropdown .btn-secondary{
    background-color: transparent;
    color: #4A4A4A;
    border-color: transparent;
    box-shadow: none;
    padding-top: 0px;
    padding-bottom: 0px;
}
.dropdown-toggle::after{
    display: none;
}
.navbar-brand img{
    width: 40px;
    margin-left: 25px;
}
tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    padding: 1px 5px !important;
    vertical-align: middle;
}
.table-responsive .dataTables_length{
    display: inline-block;
    margin-bottom: 15px;
    font-size: 12px;
}
.table-responsive .dataTables_filter{
    display: inline-block;
    margin-left: 50px;
    font-size: 12px;
    margin-bottom: 15px;
}
.table-responsive div.dataTables_wrapper div.dataTables_filter input{
    border-radius: 50px;
    box-shadow: none;
    border: 1px solid #552B84;
    outline: none;
    padding: 5px;
}
.table-responsive div.dataTables_wrapper div.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;
    display: inline-block;
    padding-top: 0.85em;
}
.table-responsive div.dataTables_wrapper div.dataTables_info {
    padding-top: 0.85em;
    display: inline-block;
    float: right;
    font-size: 12px;
}
.table-responsive{
    padding: 1px;
    position: relative;
}
.add_btns .btn-primary{
    border: 1px solid #552B84;
    color: #552B84;
    margin-left: 10px;
    width: 100px;
    font-size: 13px;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    padding: 5px !important;
}
.add_btns .btn-primary:hover{
    background-color: #552B84;
    color: #fff;
}
.add_btns{
    position: absolute;
    right: 0px;
}
.table-responsive div.dataTables_wrapper div.dataTables_length select {
    width: auto;
    display: inline-block;
    border-radius: 50px;
    box-shadow: none;
    border: 1px solid #552B84;
    outline: none;
    padding: 5px;
}
.main_user_nav img{
    width: 25px;
}
.main_user_nav a i{
    font-size: 24px;
    margin-right: 15px;
    color: #552B84;
}
table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_desc:after{
    display: none;
}
 th::before{
    display: none !important;
}
th:after{
    display: none !important;
}
.form-label{
    font-size: 12px;
    font-weight: 300;
}
.btn-primary{
    background-color: #552B84 !important;
    color: #fff !important;
    border-color: #552B84 !important;
    padding: 5px 15px !important;
    font-size: 12px !important;
}
.text-primary{
    color: #552B84 !important;
}
.btn-primary:hover{
    background-color: #fff;
    color: #552B84;
    border-color: #552B84;
    
}
.btn-secondary:hover{
    background-color: #fff;
    color: #552B84;
    border-color: #552B84;
}
.dropdown-menu{
    min-width: auto;
}
.form-select, .form-control{
    font-size: 11.5px;
    border: 1px solid #552B84;
}
.addcity_btn .btn-primary{
    width: 135px;
}
.modal-footer{
    justify-content: center;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    background-color: #552B84;
    color: #fff;
    border-radius: .25rem;
}
.nav-tabs .nav-link{
    border: 1px solid transparent !important;
    color: #552B84;
    width: auto;
    height: auto;
    font-size: 12px;
    text-align: center;
    font-style: italic;
    padding: 5px 15px !important;
}
.nav-tabs {
    border-bottom: 0px solid #dee2e6;
}
.thirdpartyassignment .nav-link{
    width: auto !important;
}
.search-label .form-control::placeholder {
    color: transparent;
}
.search-label .form-control{
    padding-left: 35px !important; 
}
.table thead tr th{
    font-weight:700 !important;
}
.dropdown-item a{
    text-decoration: none;   
    color: #552B84;
    font-size: 12px;
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu {
    top: 30px !important;
    padding: 0px !important;
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item{
    border-bottom: 1px solid #552B84;
    padding: 0rem 1rem !important;

}
.react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item:hover{
    background: rgba(85, 43, 132, 0.18);
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item:last-child{
    border-bottom: none !important;
}
.react-bootstrap-table .table-striped{
text-align: center;
}
.tab-content{
    padding-top: 0px;
}
.master_boxinfo .tab-content{
    padding-top: 0px;
}
.new_conetent-col{
    margin-top: 80px;
}




.date-box, .month-box{
    border: 1px solid #552B84 !important;
}
.sub-admin-box .slick-arrow{
    display: none !important;
}
.border-box{
    width: 70px;
    font-size: 14px;
}
.border-box span{
    font-size: 14px;
    color: #552B84;
    font-style: italic;
}
.label-headeing{
    font-size: 14px;
    color: #552B84;
    font-style: italic;
    font-weight: bold;
}
.sub-admin-sub-tittle{
    font-size: 14px;
    color: #552B84;
    font-style: italic;
}
.sub-admin-tittle{
    font-size: 16px;
    color: #552B84;
    font-style: italic;
}

.deactivelist{
    position: absolute;
    width: 225px;
    margin-left: 248px;
    /* left: 16.9%; */
    /* margin-left: 292px;
    -webkit-margin-start: 262px; */
    
}
.deactivelist .form-select{
    border: 1px solid #552B84;
    width: 125px;
}
.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.3em;
}
.btn-finalize{
    background-color: #cf7f59 !important; 
    color: #fff !important;
    border-color: #cf7f59 !important; 
    padding: 5px 15px !important;
    font-size: 12px !important;
    margin-top: 1px;
}
.td-finalize {
    width: 5% !important;
}