.dialog-layout{
    display:flex;
    flex-direction:column;
    text-align:center;
    padding:10px;
}
.dialog-layout button{
    position: absolute;
    right: 5px;
    width: 30px;
    outline: 0;
    height: 30px;
    padding: 0px;
    top:5px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dialog-layout > button span{
    font-size: 20px;
    color: #7d7d7d;
    margin:0;
}
.dialog-layout > button:hover{
    border-radius: 100%;
    background: #f1f1f1;
}
.dialog-layout span{
    font-size: 50px;
    color:#552B84;
    margin:0px auto 10px;
}
.dialog-btn-layout{
    display: flex;
    align-items: center;
    justify-content: center;
}
.alert-cancel{
    background: #bbbbbb;
    color:#fff;
    border:none;
    border-radius: 5px;
    padding: 7px 25px;
    font-size: 13px;
}
.alert-cancel:hover{
    background: #999999;
    color:#fff;
}
.alert-dialog-warning{
    color:#fff;
    border:none;
    border-radius: 5px;
    padding: 7px 25px;
    font-size: 13px;
    background:#552B84;
}
.alert-dialog-warning:hover{
    background:#7644af;
    color:#fff;
}
.modal-click a{
    text-decoration: none !important;
}
.modal-click p{
    font-size: 18px;
    margin-top: 10px;
    word-break: "break-word"
}
.modal-width .modal-dialog{
    width: 33%;
}