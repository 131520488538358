.bookstocktable-box .form-check-inline{
    margin-bottom: 0px;
}
.bookstocktable-box .records-col{
    width: 10% !important;
}
/* .bookstocktable-box .table-striped>tbody>tr:nth-of-type(odd)>*{
    background-color: rgb(85 43 132 / 20%);
} */
.padinginrow{
    padding: 0.5mm 0.5mm !important;
}
.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.3em;
}
.customized-table th, td {
    width: 29px !important;
}
.customized-table-bookstock th,td {
    width: auto !important;
}
.fixed-table-height{
    max-height:82px;
    overflow-y: scroll;
}
.fixed-bookstock-table-height{
    max-height: 116px;
    overflow-y: scroll;
}
.table-header {
    position: sticky;
    top: 0;
    background-color: #fff !important;
    z-index: 1000;
}
input[type=file]::file-selector-button {
    font-weight: bold;
    min-height: 30px;
}
.scrollable-div {
    scrollbar-width:thin;
    scrollbar-color: #888 #f0f0f0;
}
.scrollable-div::-webkit-scrollbar  {
    width: 5px;
}

/* Define the scrollbar thumb (the draggable part of the scrollbar) */
.scrollable-div::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 6px; 
}

/* Define the scrollbar track on hover */
.scrollable-div::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
}