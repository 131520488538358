.navbar {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
}

.main_user_nav {
    display: flex;
    align-items: center;
}

.user_box {
    display: flex;
    align-items: center;
}

.main_tabs #v-pills-tab .nav-link {
    padding: 25px;
    border-top: 1px solid rgba(85, 43, 132, 0.3);
    border-radius: 0px;
    color: #000 !important;
    display: grid;
    text-align: center;
}

.nav-pills .nav-link i {
    font-size: 24px;
    margin-right: 0px !important;
    color: #552B84;
    margin-bottom: 8px;
}

.main_tabs #v-pills-tab .nav-link.active,
.main_tabs #v-pills-tab .show>.nav-link {
    background-color: #fff;
    border-left: 5px solid #552B84;
}

.main_tabs #v-pills-tab {
    background: rgba(85, 43, 132, 0.18);
    height: 100%;
}

.master_boxinfo h2 {
    font-weight: 300;
    color: #4A4A4A;
}

.master_boxinfo #pills-tab .nav-link {
    border: 1px solid transparent;
    color: #552B84;
    margin-left: 15px;
    width: 105px;
    height: 35px;
    font-size: 13px;
    text-align: center;
}

.master_boxinfo #pills-tab .nav-link.active,
.master_boxinfo #pills-tab .show>.nav-link {
    background-color: #552B84;
    color: #fff;
}

.edit_icons a {
    margin: 5px;
    font-size: 13px;
    padding: 0px;
    color: #552B84;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #1e2125;
    background-color: transparent;
}

table {
    caption-side: bottom;
    border-collapse: collapse;
    font-size: 11.5px;
}

.main_user_nav h5 {
    font-size: 13px;
}

.paginate_button {
    position: relative;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #555 !important;
    background-color: #fff;
    border: 1px solid #dee2e6;
    text-decoration: none;
    cursor: pointer;
    font-size: 12px;
}

.paginate_button:hover {
    background-color: rgba(85, 43, 132, 0.3);
    color: #fff !important;
}

.paginate_button.current {
    background-color: rgba(85, 43, 132, 0.3);
    color: #fff !important;
}

.paginate_button:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.paginate_button:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.edit_icons .dropdown .btn-secondary {
    background-color: transparent;
    color: #4A4A4A;
    border-color: transparent;
    box-shadow: none;
    padding-top: 0px;
    padding-bottom: 0px;
}

.dropdown-toggle::after {
    display: none;
}

.navbar-brand img {
    width: 40px;
    margin-left: 25px;
}

#datatable8 tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    padding: 1px !important;
    vertical-align: middle;
}

.table-responsive .dataTables_length {
    display: inline-block;
    margin-bottom: 15px;
    font-size: 12px;
    position: relative;
}

.table-responsive .dataTables_filter {
    display: inline-block;
    margin-left: 50px;
    font-size: 12px;
    margin-bottom: 15px;
    position: relative;
}

.table-responsive div.dataTables_wrapper div.dataTables_filter input {
    border-radius: 50px;
    box-shadow: none;
    border: 1px solid #552B84;
    outline: none;
    padding: 5px;
    position: absolute;
    left: -10px;
    top: -5px;
    padding-left: 25px;
}

.table-responsive div.dataTables_wrapper div.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;
    display: inline-block;
    padding-top: 0.85em;
}

.table-responsive div.dataTables_wrapper div.dataTables_info {
    padding-top: 0.85em;
    display: inline-block;
    float: right;
    font-size: 12px;
}

.table-responsive {
    padding: 1px;
    position: relative;
}

.add_btns .btn-primary {
    border: 1px solid #552B84;
    color: #552B84;
    margin-left: 10px;
    width: 130px;
    min-width: 118px;
    font-size: 13px;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    padding: 5px !important;
}

.add_btns .btn-primary:hover {
    background-color: #552B84;
    color: #fff;
}

.add_btns {
    position: absolute;
    right: 0px;
}

.table-responsive div.dataTables_wrapper div.dataTables_length select {
    width: 100px;
    display: inline-block;
    border-radius: 50px;
    box-shadow: none;
    border: 1px solid #552B84;
    outline: none;
    padding: 5px;
    position: absolute;
    top: -5px;
    left: 0px;
}

.main_user_nav img {
    width: 25px;
}

.main_tabs #v-pills-tab .nav-link span {
    line-height: 1;

    font-weight: 500;
}

.clients_details h5 {
    font-size: 12px;
}

.modal-title {
    font-size: 13px;
}

.modal-header .btn-close {
    font-size: 12px;
    color: #552B84;
    opacity: 10;
}

.clients_details h5 i {
    color: #552B84;
}

.modal-header {
    padding: 8px 15px;
}

.addclients_details label {
    font-size: 11.5px;
}

.addclients_details .form-control {
    /* padding: 2px; */
    font-size: 12px;
}

.form_btn .btn-primary {
    font-size: 12px;
    background-color: #552B84;
    border-color: transparent;
    box-shadow: none;
    outline: none;
}

.master_boxinfo #myTab .nav-link {
    border: 1px solid transparent;
    color: #552B84;
    margin-left: 15px;
    width: 105px;
    height: 35px;
    font-size: 13px;
    border-radius: 0.25rem;
    text-align: center;
}

#myTab .nav-link.active {
    background-color: #552B84;
    color: #fff;
}

.form_btn {
    text-align: right;
}

.dataTables_length label> {
    display: none;
}

.user_box a {
    font-size: 24px;
    margin-left: 15px;
    color: #552B84;
}

.btn-outline-primary {
    color: #552B84;
    border-color: #552B84;
    font-size: 12px;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #552B84;
    border-color: #552B84;
}

.add_btns {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add_btns .form-select {
    width: 180px;
    font-size: 13px;
    border: 1px solid #552B84;
    outline: none;
    box-shadow: none !important;
}

.text-normal {
    font-style: normal !important;
}

.add_btns .searchButton {
    border: 1px solid #552B84;
    color: #ffffff;
    margin-left: 10px;
    font-size: 13px;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    padding: 5px !important;
    background-color: #552B84 !important;
    border-radius: .25rem;
    min-width: 50px;

}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.3em;
}

/* th,
td {
    width: 600px !important;
} */

.bills-table>:not(:first-child) {
    border-top: 1px solid #000;
}

.border-style {
    border: 1px solid #552B84;
}

.select-line-height {
    line-height: 1.3 !important;
}
/* 
.react-bootstrap-table th, .react-bootstrap-table td {

    width: 100px;
} */
.custom-header-class{
    width: 180px !important;
}
.table-height{
    min-height: 50vh;
}
.grey-icon{
    color: #D3D3D3;
}