.uploadbook_box .css-319lph-ValueContainer{
    margin-top: -2.5px;
}
.uploadbook_box .css-1s2u09g-control{
    min-height: 31.5px !important;
    height: 31.5px !important;
}
.uploadbook_box .css-1s2u09g-control:focus{
    min-height: 31.5px !important;
    height: 31.5px !important;
}
.uploadbook_box .css-1pahdxg-control{
    min-height: 31.5px !important;
    height: 31.5px !important;
    box-shadow: 0 0 0 0 #552B84 !important;
    border-color: #552B84 !important;
}
.uploadbook_box .css-1okebmr-indicatorSeparator{
    margin-top: 5px;
}
.upload-label{
    height: 32px;
    display: block;
    margin-top: 13px;
}
.uploadbook_box .css-tj5bde-Svg{
    margin-top: -3px;
}
.emptyHight{
    height: 45px;
    margin-top: 2.4PX;
}
h6{
    font-size: 14px;
}
.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.3em;
}
.main-container{
    position: fixed;
    width: 80%;
}
.form-control {
    font-size: 11.3px !important;
    min-height: 31.5px;
}
.expand_data {
    position: absolute;
    right: 0px;
}
.subjectInput{
    font-size: 13px !important;
}
.btn-container{
    border: 1px solid #cbcbcd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 10px;
    margin-top: 5px;
}
.upload-bookstock-btn{
    display: flex !important;
    justify-content: flex-end;
    align-items: end;
}
.dropdown-container{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
}
.dropdown-container .ant-dropdown {
    width: 200px;
}
.dropdown-lable{
    font-size: 12px !important;
    margin-bottom: 1px !important;
    margin-right: 5px;
    font-weight: 400 !important;
}
.custom-table{
    border: 1px solid #cbcbcd;
    margin-bottom: 10px;
}
.custom-table .ant-table-thead > tr > th{
    position: sticky;
    top: 0;
    z-index: 1;
}
.custom-table .ant-table-thead > tr > th{
    height: 38px;
}
.custom-table .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    border-color: #cbcbcd4d !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.custom-table .ant-table-wrapper {
    overflow: auto;
}
.custom-table .ant-table-body {
    /* margin-top: 38px; */
}
  
.bottom-btn{
    text-align: center;
    justify-content: center;
    padding: 10px;
    bottom: 0;
    position: fixed;
    width: 80%;
    background-color: #ffffff;
}
.loader{
    width: 83.33%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cell-row{
    height: auto;
    min-height: 30px;
    border: 1px solid #cbcbcd;
}
.cell-border{
    flex: 1;
    box-sizing: border-box;
    display: flex;
    padding: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Arial, sans-serif;
    border-right: 1px solid #cbcbcd;
}
/* Style for the right border of the second cell */
.cell-border:last-child {
    border-right: none;
}
.ant-badge-status-text{
    font-size: 12px !important;
}