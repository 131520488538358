.css-b62m3t-container {
    width:100%;
}
.css-qc6sy-singleValue{
    font-size: 11.3px;
    font-weight: normal
}

.react-select__menu-list{
    font-size: 11.5px;
}

.reduceFonts{
    font-size: 12px !important;
}
.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.3em;
}
.country_dropdown .dropdown-toggle {
    width: 100%;
}
.country_dropdown .regular-dropdown-layout{
    width: 100%;
}